import { Component, OnDestroy, OnInit } from "@angular/core"
import { Title } from "@angular/platform-browser"
import { ActivatedRoute, Router } from "@angular/router"
import { PBSLM_TITLE } from "../models/constants"
import { LearningObject } from "../models/learningObject.model"
import { CHNode } from "../models/node.model"
import { BrowsingService } from "../services/browsing.service"
import { GTMService } from "../services/gtm.service"
import { UserService } from "../services/user.service"
import { ErrorCode } from "./error-code.model"
import { AddOnViewService } from "../services/add-on-view.service"

@Component({
    templateUrl: "./error.component.html",
    styleUrls: ["./error.component.scss"],
})
export class ErrorComponent implements OnInit, OnDestroy {
    ErrorCode = ErrorCode
    errorCode: ErrorCode
    customErrorMessage: string
    isStudent: boolean
    nextURL: string
    customMessage: string
    isAddOnView: boolean

    subjects: CHNode[]
    learningObjects: LearningObject[] = []
    expirationDate: string = null

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private gtmService: GTMService,
        private titleService: Title,
        private window: Window,
        private browsingService: BrowsingService,
        private router: Router,
        private addOnViewService: AddOnViewService
    ) {
        this.isStudent = this.userService.hasStudentExperience()
        this.setExpiredResourceData()
        this.setResourceAdminButtons()
    }

    public ngOnInit() {
        this.isAddOnView = this.addOnViewService.isAddonView
        this.browsingService
            .getSubjectsForErrorPage()
            .subscribe((data: CHNode[]) => (this.subjects = data))

        // TODO: When we'll have router links to embedded pages (tools)
        // make sure we reset the "PBSLM.ERROR_CODE" variable or check the url so that we don't loop infinitely.
        const queryParams = this.route.snapshot.queryParamMap
        this.errorCode = (queryParams.get("status") as ErrorCode) || ErrorCode.Error404
        this.customErrorMessage = queryParams.get("customErrorMessage") || null
        this.nextURL = queryParams.get("next") || null
        this.titleService.setTitle(`Error ${this.errorCode} | ${PBSLM_TITLE}`)
        this.gtmService.pushEvent(`Error${this.errorCode}`)
        this.gtmService.resetDataLayer()
        this.gtmService.pushPageView()

        if (this.window["PBSLM"].NG.ERROR_MESSAGE) {
            this.customMessage = this.window["PBSLM"].NG.ERROR_MESSAGE
            window["PBSLM"].NG.ERROR_MESSAGE = null
        }
    }

    private setExpiredResourceData() {
        const routerData = this.router.getCurrentNavigation()?.extras?.state?.data
        this.learningObjects = routerData ? routerData.relatedResources : []
        this.expirationDate = routerData ? routerData.expirationDate : null
    }

    setResourceAdminButtons() {
        const adminLink = this.router.getCurrentNavigation()?.extras?.state?.data?.adminLink
        if (adminLink)
            this.userService.emitAdminHeaderData({
                title: adminLink.title,
                adminLink: adminLink.url,
                isPublished: null,
            })
    }

    ngOnDestroy() {
        this.userService.emitAdminHeaderData({})
    }
}
